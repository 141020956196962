/* eslint-disable no-unused-vars, no-console */
export const state = {
    termsAndConditionsData: []
}

export const getters = {}

export const mutations = {
    SET_TERMS_AND_CONDITIONS(state, data) {
        state.termsAndConditionsData = data
    }
}

export const actions = {
    stateSetTermsAndConditions({ commit, state, rootState }, data) {
        commit('SET_TERMS_AND_CONDITIONS', data)
    }
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    jobIndustriesData: [],
    jobIndustryFULLData: []
}

export const getters = {}

export const mutations = {
    SET_JOB_INDUSTRIES(state, data) {
        state.jobIndustriesData = data
    },
    SET_INDUSTRIES_FULL(state, data) {
        state.jobIndustryFULLData = data
    },
}

export const actions = {
    stateSetJobIndustries({ commit, state, rootState }, data) {
        commit('SET_JOB_INDUSTRIES', data)
    },
    stateSetJobIndustriesFull({ commit, state, rootState }, data) {
        commit('SET_INDUSTRIES_FULL', data)
    }
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    packagesData: {}
}

export const getters = {}

export const mutations = {
    SET_PACKAGES(state, data) {
        state.packagesData = data
    }
}

export const actions = {
    stateSetPackages({ commit, state, rootState }, data) {
        commit('SET_PACKAGES', data)
    }
}

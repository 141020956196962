/* eslint-disable no-unused-vars, no-console */
export const state = {
    paymentStagesInfo: {}
}

export const getters = {}

export const mutations = {
    SET_PAYMENT_STAGES(state, data) {
        state.paymentStagesInfo = data
    }
}

export const actions = {
    stateSetPaymentStages({ commit, state, rootState }, data) {
        commit('SET_PAYMENT_STAGES', data)
    }
}

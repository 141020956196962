/* eslint-disable no-unused-vars, no-console */
export const state = {
    displayPhoto: ''
}

export const mutations = {
    SET_PROFILE_PHOTO(state, data) {
        state.displayPhoto = data
    }
}

export const actions = {
    stateSetProfileDisplayPhoto({ commit, state, rootState }, data) {
        commit('SET_PROFILE_PHOTO', data)
    }
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    addressId: null,
    buildingId: null,
    predictions: []
}

export const getters = {}

export const mutations = {
    SET_ASSETS_PREDICTIONS(state, data) {
        state.addressId = data.addressId
        state.buildingId = data.buildingId
        state.predictions = data.predictions
    }
}

export const actions = {
    stateSetAssetsPredictions({ commit, state, rootState }, data) {
        commit('SET_ASSETS_PREDICTIONS', data)
    }
}

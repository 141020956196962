/* eslint-disable no-unused-vars, no-console */
export const state = {
    jobEnterprisesData: [],
    jobEnterprisesNameInfo: {}
}

export const getters = {}

export const mutations = {
    SET_JOB_ENTERPRISES(state, data) {
        state.jobEnterprisesData = data
    },
    SET_JOB_ENTERPRISES_NAME(state, data) {
        state.jobEnterprisesNameInfo = data
    },
}

export const actions = {
    stateSetJobEnterprises({ commit, state, rootState }, data) {
        commit('SET_JOB_ENTERPRISES', data)
    },
    stateSetJobEnterprisesName({ commit, state, rootState }, data) {
        commit('SET_JOB_ENTERPRISES_NAME', data)
    },
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    contractsData: [],
    contractstemplate: []
}

export const getters = {}

export const mutations = {
    SET_CONTRACTS(state, data) {
        state.contractsData = data
    },
    SET_CONTRACT_TEMPLATE(state, data) {
        state.contractstemplate.push(data)
    },
    SET_CONTRACT_TEMPLATE_EMPTY(state, data) {
        state.contractstemplate = []
    }
}

export const actions = {
    stateSetContracts({ commit, state, rootState }, data) {
        commit('SET_CONTRACTS', data)
    },
    stateSetContractTemplate({ commit, state, rootState }, data) {
        commit('SET_CONTRACT_TEMPLATE', data)
    },
    stateSetContractTemplateEmpty({ commit, state, rootState }, data) {
        commit('SET_CONTRACT_TEMPLATE_EMPTY', data)
    },
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    videosData: []
}

export const getters = {}

export const mutations = {
    SET_VIDEOS(state, data) {
        state.videosData = data
    }
}

export const actions = {
    stateSetVideos({ commit, state, rootState }, data) {
        commit('SET_VIDEOS', data)
    }
}

export default {
  mounted() {
    const userAgent = this.getBrowser()
    if (['FxiOS', 'CriOS', 'Safari'].includes(userAgent.engine) && userAgent.platform === 'iOS') {
      this.preventZoomOnTouchMove()
    }
  },
  methods: {
    getBrowser() {
      const userAgent = navigator.userAgent
      const isIOS = userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1
      if (userAgent.includes('FxiOS')) {
        return {
          engine: 'FxiOS',
          platform: isIOS ? 'iOS' : 'Other'
        }
      }
      if (userAgent.includes('CriOS')) {
        return {
          engine: 'CriOS',
          platform: isIOS ? 'iOS' : 'Other'
        }
      }
      if (userAgent.includes('Safari/') && !userAgent.includes('CriOS')) {
        return {
          engine: 'Safari',
          platform: isIOS ? 'iOS' : 'Other'
        }
      }
      return {
        engine: 'Other',
        platform: 'Other'
      }
    },
    preventZoomOnTouchMove() {
      const appElement = document.body
      appElement.addEventListener('touchstart', function(event) {
        if (event.scale >= 1.5 || event.touches.length > 1) {
          event.preventDefault()
        }
      }, { passive: false })
      appElement.addEventListener('touchmove', function(event) {
        if (event.scale >= 1.5 || event.touches.length > 1) {
          event.preventDefault()
        }
      }, { passive: false })
    }
  }
}
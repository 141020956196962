/* eslint-disable no-unused-vars, no-console */
export const state = {
    eventsData: [],
    eventTypesData: []
}

export const getters = {}

export const mutations = {
    SET_EVENTS(state, data) {
        state.eventsData = data
    },
    SET_EVENT_TYPES(state, data) {
        state.eventTypesData = data
    }
}

export const actions = {
    stateSetEvents({ commit, state, rootState }, data) {
        commit('SET_EVENTS', data)
    },
    stateSetEventTypes({ commit, state, rootState }, data) {
        commit('SET_EVENT_TYPES', data)
    }
}

// index.js
import en from './translation/minbolig/en.json'
import dk from './translation/minbolig/da.json'
import co from './translation/minbolig/co.json'

export const defaultLocale = 'dk'

export const languages = {
  en: en,
  dk: dk,
  co: co
}
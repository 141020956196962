/* eslint-disable no-unused-vars, no-console */
export const state = {
    newsLetterSettingsInfo: {}
}

export const getters = {}

export const mutations = {
    SET_NEWSLETTER_SETTINGS(state, data) {
        state.newsLetterSettingsInfo = data
    }
}

export const actions = {
    stateSetNewsLetterSettings({ commit, state, rootState }, data) {
        commit('SET_NEWSLETTER_SETTINGS', data)
    }
}

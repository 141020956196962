/* eslint-disable no-unused-vars, no-console */
export const state = {
    parentProjectsData: [],
    subProjectsData: [],
    activeParentProjectInfo: {},
    activeSubProjectInfo: {},
    parentProjectsCount: null
}

export const getters = {}

export const mutations = {
    SET_PARENT_PROJECTS(state, data) {
        state.parentProjectsData = data;
    },
    SET_SUB_PROJECTS(state, data) {
        state.subProjectsData = data;
    },
    SET_ACTIVE_PARENT_PROJECT(state, data) {
        state.activeParentProjectInfo = data;
    },
    SET_ACTIVE_SUB_PROJECT(state, data) {
        state.activeSubProjectInfo = data;
    },
    SET_PARENT_PROJECTS_COUNT(state, data) {
        state.parentProjectsCount = data;
    },
}

export const actions = {
    stateSetParentProjects({ commit, state, rootState }, data) {
        commit('SET_PARENT_PROJECTS', data)
    },
    stateSetSubProjects({ commit, state, rootState }, data) {
        commit('SET_SUB_PROJECTS', data)
    },
    stateSetParentActiveProject({ commit, state, rootState }, data) {
        commit('SET_ACTIVE_PARENT_PROJECT', data)
    },
    stateSetSubActiveProject({ commit, state, rootState }, data) {
        commit('SET_ACTIVE_SUB_PROJECT', data)
    },
    stateSetParentProjectsCount({ commit, state, rootState }, data) {
        commit('SET_PARENT_PROJECTS_COUNT', data)
    }
}

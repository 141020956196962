/* eslint-disable no-unused-vars, no-console */
export const state = {
    craftsmanData: []
}

export const getters = {}

export const mutations = {
    SET_CRAFTSMAN_CONTACTS(state, data) {
        state.craftsmanData = data
    }
}

export const actions = {
    stateSetCraftsmanContacts({ commit, state, rootState }, data) {
        commit('SET_CRAFTSMAN_CONTACTS', data)
    }
}

// import store from '@/state/store'
/* eslint-disable no-unused-vars, no-console */
import Vue from 'vue'

export default [{
    path: '/',
    name: 'Index',
    component: () =>
      import ('./views/index'),
      meta: { 
        authRequired: true,
        beforeResolve(routeTo, routeFrom, next) {
          Vue.prototype.$savePreviousPageInfo(routeFrom)
          // If the user is not logged in
          if (!localStorage.getItem('id_token_portal')) {
            // Redirect to the login page
            next({ name: 'login' })
          } else {
            // Continue to the page
            next()
          }
        }, 
      },
  },
  // {
  //   path: '/messages/inbox',
  //   name: 'Messages',
  //   component: () =>
  //     import ('./views/messages'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  {
    path: '/messages/inbox',
    name: 'Inbox',
    component: () =>
      import ('./views/inbox'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/generator/*',
    name: 'htmlgenerator',
    component: () =>
      import ('./views/htmlgenerator'),
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/messages/compose',
    name: 'Ny mail',
    component: () =>
      import ('./views/email/composenewmail'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/messages/sent',
    name: 'Sent mail',
    component: () =>
      import ('./views/sent'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/messages/trash',
    name: 'Slettede mail',
    component: () =>
      import ('./views/email/deletedmail'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () =>
      import ('./views/documents'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/tilbudsoversigt',
    name: 'Tilbudsoversigt',
    component: () =>
      import ('./views/tilbudsoversigt'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () =>
      import ('./views/favorites'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/annullere_kontrakten',
    name: 'Cancel Contract',
    component: () =>
      import ('./views/Cancel_contract'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/pdf',
  //   name: 'PDf',
  //   component: () =>
  //       import ('./views/pdf'),
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       Vue.prototype.$savePreviousPageInfo(routeFrom)
  //       // If the user is not logged in
  //       if (!localStorage.getItem('id_token_portal')) {
  //         // Redirect to the login page
  //         next({ name: 'login' })
  //       } else {
  //         // Continue to the page
  //         next()
  //       }
  //     }, 
  //   }
  // },
  // {
  //   path: '/add-address',
  //   name: 'Add address',
  //   component: () =>
  //     import ('./views/addAddress'),
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       Vue.prototype.$savePreviousPageInfo(routeFrom)
  //       // If the user is not logged in
  //       if (!localStorage.getItem('id_token_portal')) {
  //         // Redirect to the login page
  //         next({ name: 'login' })
  //       } else {
  //         // Continue to the page
  //         next()
  //       }
  //     }, 
  //   }
  // },
  {
    path: '/update-address',
    name: 'Update address',
    component: () =>
      import ('./views/updateAddress'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/updated-address-overview',
    name: 'Updated address overview',
    component: () =>
      import ('./views/updatedAddressOverview'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/home-data',
    name: 'Home Data',
    component: () =>
      import ('./views/home-data'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/home-charts',
    name: 'Home Data Charts',
    component: () =>
      import ('./views/home-charts'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () =>
      import ('./views/kalender'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/tui-calendar',
    name: 'TUI Calendar',
    component: () =>
      import ('./views/tui-calendar'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/projects-dashboard',
    name: 'My Project',
    component: () =>
      import ('./views/projects-dashboard'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/project-timeline',
    name: 'Latest Timeline',
    component: () =>
      import ('./views/projects/gantt'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculate/house',
    name: 'Property Value',
    component: () =>
      import ('./views/calculate/house'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculate/schedule',
    name: 'Schedule Maintenance',
    component: () =>
      import ('./views/calculate/schedule'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculate/maintenance-plan',
    name: 'Maintenance Plan',
    component: () =>
      import ('./views/calculate/maintenance-plan'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculate/investment-path',
    name: 'Investment Path',
    component: () =>
      import ('./views/calculate/investment-path'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculate/investment-proposal',
    name: 'Investment Proposal',
    component: () =>
      import ('./views/calculate/investment-proposal'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/create-project',
    name: 'Create Project',
    component: () =>
      import ('./views/createproject'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/create-contract/temp-diff',
  //   name: 'Temp Diff',
  //   component: () =>
  //     import ('./views/wallet/tempDiff'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  {
    path: '/offer/status',
    name: 'Offer Status',
    component: () =>
      import ('./views/offer/status'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/offer/rediger',
    name: 'Offer Edit',
    component: () =>
      import ('./views/offer/editOffer'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/offer/enterprises',
    name: 'Offer Enterprises',
    component: () =>
      import ('./views/offer/enterprises'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/design-elements',
    name: 'Design Elements',
    component: () =>
      import ('./views/design-elements'),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/pay-proff',
    name: 'Pay Proff',
    component: () =>
      import ('./views/pay-proff'),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/pay-proff-kyc',
    name: 'Pay Proff Kyc',
    component: () =>
      import ('./views/payproff_kyc'),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import ('./views/profile'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile/security',
    name: 'Security and Privacy',
    component: () =>
      import ('./views/security'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    },
  },
  {
    path: '/profile/connections',
    name: 'Connections',
    component: () =>
      import ('./views/connections'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    },
    /* children: [
        {
            path: '/profile/myprofile',
            name: 'My Profile',
            component: () => import('./views/profile/myprofile'),
        },
        {
            path: '/profile/security',
            name: 'Security and Privacy',
            component: () => import('./views/profile/security'),
        },
        {
            path: '/profile/connections',
            name: 'Connections',
            component: () => import('./views/profile/connections'),
        },
        {
            path: '/profile/rewards',
            name: 'Connections',
            component: () => import('./views/profile/rewards'),
        }
    ] */
  },
  {
    path: '/profile/rewards',
    name: 'Rewards',
    component: () =>
      import ('./views/rewards'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile/notifications',
    name: 'Notifications',
    component: () =>
      import ('./views/notifications'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile/subscription',
    name: 'Subscription',
    component: () =>
      import ('./views/subscription'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile/packages',
    name: 'Packages',
    component: () =>
      import ('./views/packages'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/profile/credit-card',
    name: 'Credit card',
    component: () =>
      import ('./views/credit-card'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/images/editor',
    name: 'Edit your image',
    component: () =>
      import ('./views/image-editor'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/tui-editor',
    name: 'TUI Editor',
    component: () =>
      import ('./views/tui-editor'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/pinterest',
    name: 'My Pinterest',
    component: () =>
      import ('./views/pinterest'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/recommendations',
    name: 'Recommendations',
    component: () =>
      import ('./views/recommendations'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/ejendom/home',
    name: 'Real Estate Home',
    component: () =>
      import ('./views/realestate/home-realestate'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/ejendom/huse',
    name: 'Real Estate Houses for Sale',
    component: () =>
      import ('./views/realestate/houses-realestate'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/anbefal',
    name: 'CreateRecommendations',
    component: () =>
      import ('./views/createrecommendation'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/inspiration',
    name: 'Inspiration',
    component: () =>
      import ('./views/inspiration'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/image-documentation',
    name: 'Billeddokumentation',
    component: () =>
      import ('./views/image-documentation'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import ('./views/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If not from autologin url & not from hvdk & the user is already logged in
        var isFromAutoLogin = window.location.href.includes('content') || window.location.href.includes('fromhvdk') ? true : false
        if (!isFromAutoLogin && localStorage.getItem('id_token_portal')) {
          // Redirect to the home page instead
          next({ name: 'Index' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import ('./views/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is already logged in
        if (localStorage.getItem('id_token_portal')) {
          // Redirect to the home page instead
          next({ name: 'Index' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      import ('./views/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is already logged in
        if (localStorage.getItem('id_token_portal')) {
          // Redirect to the home page instead
          next({ name: 'Index' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import ('./views/reset-password'),
    meta: {
      // beforeResolve(routeTo, routeFrom, next) {
      //   Vue.prototype.$savePreviousPageInfo(routeFrom)
      //   // If the user is already logged in
      //   if (localStorage.getItem('id_token_portal')) {
      //     // Redirect to the home page instead
      //     next({ name: 'Index' })
      //   } else {
      //     // Continue to the login page
      //     next()
      //   }
      // },
    },
  },
  {
    path: '/social-register',
    name: 'socialRegister',
    component: () =>
      import ('./views/social-register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is already logged in
        if (localStorage.getItem('id_token_portal')) {
          // Redirect to the home page instead
          next({ name: 'Index' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear()
        Vue.prototype.$globalNotificationData = []
        Vue.prototype.$globalNotificationShow = false
        // Reset state, don't remove this!
        window.location.replace('/login?logout=true')
      },
    },
  },
  // {
  //     path: '/calendar',
  //     name: 'Calendar',
  //     component: () => import('./views/calendar/index'),
  //     meta: {
  //         authRequired: true,
  //         beforeResolve(routeTo, routeFrom, next) {
  //           Vue.prototype.$savePreviousPageInfo(routeFrom)
  //           // If the user is not logged in
  //           if (!localStorage.getItem('id_token_portal')) {
  //             // Redirect to the login page
  //             next({ name: 'login' })
  //           } else {
  //             // Continue to the page
  //             next()
  //           }
  //         }, 
  //     },
  // },
  {
    path: '/email/inbox',
    name: 'Email Inbox',
    component: () =>
      import ('./views/email/inbox'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/email/read-email',
    name: 'Read email',
    component: () =>
      import ('./views/email/reademail'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/email/compose',
    name: 'Compose',
    component: () =>
      import ('./views/email/compose'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/contract-manager',
  //   name: 'Contract Manager',
  //   component: () =>
  //     import ('./views/contract-manager'),
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       Vue.prototype.$savePreviousPageInfo(routeFrom)
  //       // If the user is not logged in
  //       if (!localStorage.getItem('id_token_portal')) {
  //         // Redirect to the login page
  //         next({ name: 'login' })
  //       } else {
  //         // Continue to the page
  //         next()
  //       }
  //     }, 
  //   }
  // },
  {
    path: '/payment-management',
    name: 'Payment Manager',
    component: () =>
      import ('./views/payment-management'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/new-signup',
    name: 'New Account',
    component: () =>
      import ('./views/new_signup'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/create-contract',
    name: 'Create contract',
    component: () =>
      import ('./views/createcontract'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/edit-contract',
    name: 'Editcontract',
    component: () =>
      import ('./views/editcontract'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/edit-contract2',
  //   name: 'Editcontract2',
  //   component: () =>
  //     import ('./views/editcontract_test'),
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       Vue.prototype.$savePreviousPageInfo(routeFrom)
  //       // If the user is not logged in
  //       if (!localStorage.getItem('id_token_portal')) {
  //         // Redirect to the login page
  //         next({ name: 'login' })
  //       } else {
  //         // Continue to the page
  //         next()
  //       }
  //     }, 
  //   }
  // },
  {
    path: '/incompletes',
    name: 'Incompletes',
    component: () =>
      import ('./views/contracts/incompletes'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/calculators',
    name: 'Calculators',
    component: () =>
      import ('./views/calculators'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/wallet-alerts',
    name: 'Wallet - Alerts',
    component: () =>
      import ('./views/wallet/wallet-alert'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/wallet-status',
    name: 'Wallet - status',
    component: () =>
      import ('./views/walletstatus'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/transfer-flow',
    name: 'Wallet - Transfers',
    component: () =>
      import ('./views/transferflow'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/contract-changes',
    name: 'Changes',
    component: () =>
      import ('./views/contractchanges'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/legal-help',
    name: 'Legal Help',
    component: () =>
      import ('./views/legalhelp'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/legal-terms',
    name: 'Legal Terms',
    component: () =>
      import ('./views/legalterms'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/ui/alerts',
  //   name: 'Alerts',
  //   component: () =>
  //     import ('./views/ui/alerts'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/rating',
  //   name: 'Rating',
  //   component: () =>
  //     import ('./views/ui/rating'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/buttons',
  //   name: 'Buttons',
  //   component: () =>
  //     import ('./views/ui/buttons'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/cards',
  //   name: 'Cards',
  //   component: () =>
  //     import ('./views/ui/cards'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/carousel',
  //   name: 'Carousel',
  //   component: () =>
  //     import ('./views/ui/carousel'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/colors',
  //   name: 'Colors',
  //   component: () =>
  //     import ('./views/ui/colors'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/dropdowns',
  //   name: 'Dropdowns',
  //   component: () =>
  //     import ('./views/ui/dropdowns'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/general',
  //   name: 'General',
  //   component: () =>
  //     import ('./views/ui/general'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/grid',
  //   name: 'Grid',
  //   component: () =>
  //     import ('./views/ui/grid'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/images',
  //   name: 'Images',
  //   component: () =>
  //     import ('./views/ui/images'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/modals',
  //   name: 'Modals',
  //   component: () =>
  //     import ('./views/ui/modals'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/progressbar',
  //   name: 'Progressbar',
  //   component: () =>
  //     import ('./views/ui/progressbar'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/rangeslider',
  //   name: 'Range-slider',
  //   component: () =>
  //     import ('./views/ui/rangeslider'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/tabs',
  //   name: 'Tabs & Accordions',
  //   component: () =>
  //     import ('./views/ui/tabs'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/typography',
  //   name: 'Typography',
  //   component: () =>
  //     import ('./views/ui/typography'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/ui/video',
  //   name: 'Video',
  //   component: () =>
  //     import ('./views/ui/video'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/timeline',
  //   name: 'Timeline',
  //   component: () =>
  //     import ('./views/pages/timeline'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/invoice',
  //   name: 'Invoice',
  //   component: () =>
  //     import ('./views/pages/invoice'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/pricing',
  //   name: 'Pricing',
  //   component: () =>
  //     import ('./views/pages/pricing'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/blank-page',
  //   name: 'Blank page',
  //   component: () =>
  //     import ('./views/pages/blank'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/faqs',
  //   name: 'FAQs',
  //   component: () =>
  //     import ('./views/pages/faqs'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/directory',
  //   name: 'Directory',
  //   component: () =>
  //     import ('./views/pages/directory'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  {
    path: '*',
    name: '404',
    component: () =>
      import ('./views/404'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/offline',
    name: 'Offline',
    component: () =>
      import ('./views/offline'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/500',
  //   name: 'Der er sket en serverfejl',
  //   component: () =>
  //     import ('./views/50x'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/maintenance',
  //   name: 'Maintenance',
  //   component: () =>
  //     import ('./views/pages/maintenance'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/login-1',
  //   name: 'Login-1',
  //   component: () =>
  //     import ('./views/pages/login-1'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/login-2',
  //   name: 'Login-2',
  //   component: () =>
  //     import ('./views/pages/login-2'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/register-1',
  //   name: 'Register-1',
  //   component: () =>
  //     import ('./views/pages/register-1'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/register-2',
  //   name: 'Register-2',
  //   component: () =>
  //     import ('./views/pages/register-2'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/recoverpwd-1',
  //   name: 'Recover Password 1',
  //   component: () =>
  //     import ('./views/pages/recoverpwd-1'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/recoverpwd-2',
  //   name: 'Recover Password 2',
  //   component: () =>
  //     import ('./views/pages/recoverpwd-2'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/lock-screen1',
  //   name: 'Lock-screen 1',
  //   component: () =>
  //     import ('./views/pages/lock-screen1'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/pages/lock-screen2',
  //   name: 'Lock-screen 2',
  //   component: () =>
  //     import ('./views/pages/lock-screen2'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/elements',
  //   name: 'Form Elements',
  //   component: () =>
  //     import ('./views/forms/elements'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/advanced',
  //   name: 'Form advanced',
  //   component: () =>
  //     import ('./views/forms/advanced'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/editor',
  //   name: 'Form editor',
  //   component: () =>
  //     import ('./views/forms/editor'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/mask',
  //   name: 'Form mask',
  //   component: () =>
  //     import ('./views/forms/mask'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/uploads',
  //   name: 'Form uploads',
  //   component: () =>
  //     import ('./views/forms/uploads'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/validation',
  //   name: 'Form validation',
  //   component: () =>
  //     import ('./views/forms/validation'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/repeater',
  //   name: 'Form Repeater',
  //   component: () =>
  //     import ('./views/forms/repeater'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/form/wizard',
  //   name: 'Form wizard',
  //   component: () =>
  //     import ('./views/forms/wizard'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/dripicons',
  //   name: 'Dripicons icons',
  //   component: () =>
  //     import ('./views/icons/dripicons'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/fontawesome',
  //   name: 'Font-awesome icons',
  //   component: () =>
  //     import ('./views/icons/fontawesome'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/ion',
  //   name: 'Ion icons',
  //   component: () =>
  //     import ('./views/icons/ion'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/material',
  //   name: 'Material icons',
  //   component: () =>
  //     import ('./views/icons/material'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/themify',
  //   name: 'Themify icons',
  //   component: () =>
  //     import ('./views/icons/themify'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/icons/typicons',
  //   name: 'Typicons icons',
  //   component: () =>
  //     import ('./views/icons/typicons'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/tables/basic',
  //   name: 'Basic table',
  //   component: () =>
  //     import ('./views/tables/basic'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/tables/advancedtable',
  //   name: 'Advanced table',
  //   component: () =>
  //     import ('./views/tables/advancedtable'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  {
    path: '/charts/chartist',
    name: 'Chartist',
    component: () =>
      import ('./views/charts/chartist'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  // {
  //   path: '/charts/chartjs',
  //   name: 'Chartjs',
  //   component: () =>
  //     import ('./views/charts/chartjs/index'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/charts/apex',
  //   name: 'Apex',
  //   component: () =>
  //     import ('./views/charts/apex'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/charts/echart',
  //   name: 'E - Chart',
  //   component: () =>
  //     import ('./views/charts/echart/index'),
  //   meta: {
  //     authRequired: true
  //   }
  // },
  // {
  //   path: '/email-template/basic',
  //   name: 'Email-template Basic',
  //   component: () =>
  //     import ('./views/email-template/basic'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/email-template/alert',
  //   name: 'Alert Email',
  //   component: () =>
  //     import ('./views/email-template/alert'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/email-template/billing',
  //   name: 'Billing Email',
  //   component: () =>
  //     import ('./views/email-template/billing'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  // {
  //   path: '/maps/google',
  //   name: 'Google map',
  //   component: () =>
  //     import ('./views/maps/google'),
  //   meta: {
  //     authRequired: true,
  //   }
  // },
  {
    path: '/customer-project',
    name: 'Customer Project',
    component: () =>
      import ('./views/customer-project'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () =>
      import ('./views/budget'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/budget2',
    name: 'Budgetv2',
    component: () =>
      import('./views/budgetv2'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/price-calculator',
    name: 'Price Calculator',
    component: () =>
      import ('./views/price-calculator'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/prisberegnerai',
    name: 'Prisberegner',
    component: () =>
      import ('./views/prisberegnerai'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/version',
    name: 'Version',
    component: () =>
      import ('./views/version'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/invest-home',
    name: 'Invest Home',
    component: () =>
      import ('./views/invest-home'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/credit-application',
    name: 'Credit application',
    component: () =>
      import ('./views/credit-application'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/credit-application-post',
    name: 'Credit application Post',
    component: () =>
      import ('./views/credit-application-post'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/credit-application-cs',
    name: 'Credit application CS',
    component: () =>
      import ('./views/credit-application-post'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/legal/contract',
    name: 'Legal Contract Check',
    component: () =>
      import ('./views/editcontract'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/legal/message',
    name: 'Legal Message Check',
    component: () =>
      import ('./views/inbox'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/worksheet',
    name: 'Worksheet',
    component: () =>
      import ('./views/worksheet'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/insurance',
    name: 'Insurance',
    component: () =>
      import ('./views/insurance'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/contract-addon',
    name: 'Contract Addon',
    component: () =>
      import ('./views/contract-addon'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/page-maintenance',
    name: 'Maintenance',
    component: () =>
      import ('./views/pages/page-maintenance'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/housing-credit-about',
    name: 'About Housing Credit',
    component: () =>
      import ('./views/housing-credit-about'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/loan-payment',
    name: 'Loan Payment',
    component: () =>
      import ('./views/loan-payment'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/mitid',
    name: 'Mit ID',
    component: () =>
      import ('./views/mitid'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        Vue.prototype.$savePreviousPageInfo(routeFrom)
        // If the user is not logged in
        if (!localStorage.getItem('id_token_portal')) {
          // Redirect to the login page
          next({ name: 'login' })
        } else {
          // Continue to the page
          next()
        }
      }, 
    }
  },
  {
    path: '/mitid-error',
    name: 'Mit ID Error',
    component: () =>
      import ('./views/mitid-error'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/no-menu-success-message',
    name: 'No Menu Success Message',
    component: () =>
      import ('./views/noMenuSuccessMessage'),
    meta: {
      authRequired: false
    }
  }
]
/* eslint-disable no-unused-vars, no-console */
export const state = {
    favoritesData: []
}

export const getters = {}

export const mutations = {
    SET_FAVORITES(state, data) {
        state.favoritesData = data
    }
}

export const actions = {
    stateSetFavorites({ commit, state, rootState }, data) {
        commit('SET_FAVORITES', data)
    }
}

/* eslint-disable no-unused-vars, no-console */
export const state = {
    addressesData: [],
    defaultAddress: {},
    addressValue: {},
    addressOfferSaleHistoryInfo: {}
}

export const getters = {}

export const mutations = {
    SET_ADDRESSES(state, data) {
        state.addressesData = data
    },
    SET_DEFAULT_ADDRESS(state, data) {
        state.defaultAddress = data
    },
    SET_ADDRESS_VALUE(state, data) {
        state.addressValue = data
    },
    SET_ADDRESS_OFFER_SALE_HISTORY(state, data) {
        state.addressOfferSaleHistoryInfo = data
    }
}

export const actions = {
    stateSetAddresses({ commit, state, rootState }, data) {
        commit('SET_ADDRESSES', data)
    },
    stateSetDefaultAddress({ commit, state, rootState }, data) {
        commit('SET_DEFAULT_ADDRESS', data)
    },
    stateSetAddressValue({ commit, state, rootState }, data) {
        commit('SET_ADDRESS_VALUE', data)
    },
    stateSetAddressOfferSaleHistory({ commit, state, rootState }, data) {
        commit('SET_ADDRESS_OFFER_SALE_HISTORY', data)
    }
}

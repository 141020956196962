import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
// import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

// const vuexLocalStorage = new VuexPersist({
//   key: 'vuex', // The key to store the state on in the storage provider.
//   storage: window.localStorage, // or window.sessionStorage or localForage
//   // Function that passes the state and returns the state with only the objects you want to store.
//   // reducer: state => state,
//   // Function that passes a mutation and lets you decide if it should update the state in localStorage.
//   // filter: mutation => (true)
// })

const store = new Vuex.Store({
  modules,
  // plugins: [vuexLocalStorage.plugin],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  // strict: process.env.NODE_ENV !== 'production',
  strict: false,
})

export default store


/* eslint-disable no-unused-vars, no-console */
export const state = {
    loanTypeInfo: {},
    loanApplicationsData: [],
    latestLoanInfo: {},
    loanTaskStatus: {},
    loanPaymentsData: [],
    isLoanApplicationSigned: false,
    usedCredit: 0,
    unUsedCredit: 0
}

export const getters = {}

export const mutations = {
    SET_LOAN_TYPE(state, data) {
        state.loanTypeInfo = data
    },
    SET_LOANS(state, data) {
        state.loanApplicationsData = data
        var acceptedApplications = data.filter(function (prop) { return prop.UF_STATUS == 'ACCEPTED' })
        state.usedCredit = acceptedApplications.reduce((accumulator, prop) => { return accumulator + prop.UF_APPROVED_CREDIT_LIMIT }, 0)
        state.unUsedCredit = parseFloat(state.loanTypeInfo.UF_MAXAPPLICATIONLIMIT) - state.usedCredit
    },
    SET_LATEST_LOAN(state, data) {
        state.latestLoanInfo = data
    },
    SET_LOAN_TASK_STATUS(state, data) {
        state.loanTaskStatus = data
        if (data && data.customer && data.customer.hasOwnProperty('hasActiveTask') && data.customer && !data.customer.hasActiveTask || data && data.customer && !data.customer.hasActiveTask) {
            state.isLoanApplicationSigned = true
        } else {
            state.isLoanApplicationSigned = false
        }
    },
    SET_LOAN_PAYMENTS(state, data) {
        state.loanPaymentsData = data
    },
}

export const actions = {
    stateSetLoanType({ commit, state, rootState }, data) {
        commit('SET_LOAN_TYPE', data)
    },
    stateSetLoans({ commit, state, rootState }, data) {
        commit('SET_LOANS', data)
    },
    stateSetLatestLoan({ commit, state, rootState }, data) {
        commit('SET_LATEST_LOAN', data)
    },
    stateSetLoanTaskStatus({ commit, state, rootState }, data) {
        commit('SET_LOAN_TASK_STATUS', data)
    },
    stateSetLoanPayments({ commit, state, rootState }, data) {
        commit('SET_LOAN_PAYMENTS', data)
    }
}

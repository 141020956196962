/* eslint-disable no-unused-vars, no-console */
export const state = {
    payProffProfileInfo: {}
}

export const getters = {}

export const mutations = {
    SET_PAYPROFF_PROFILE(state, data) {
        state.payProffProfileInfo = data
    }
}

export const actions = {
    stateSetPayProffProfile({ commit, state, rootState }, data) {
        commit('SET_PAYPROFF_PROFILE', data)
    }
}

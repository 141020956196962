/* eslint-disable no-unused-vars, no-console */
export const state = {
    profileProgressInfo: {}
}

export const getters = {}

export const mutations = {
    SET_PROFILE_PROGRESS(state, data) {
        state.profileProgressInfo = data
    }
}

export const actions = {
    stateSetProfileProgress({ commit, state, rootState }, data) {
        commit('SET_PROFILE_PROGRESS', data)
    }
}

<script>
import appConfig from '@/app.config'
import pinchZoomMixin from './pinchZoomMixin.js'
// import scaleMixin from './scaleMixin.js'
export default {
  name: "app",
  mixins: [pinchZoomMixin],
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.description}` : appConfig.description;
    }
  }
};
</script>
<template>
  <div id="app">
    <RouterView />
  </div>
</template>
<style>
#app {
  touch-action: pan-x pan-y;
}
</style>

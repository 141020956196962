/* eslint-disable no-unused-vars, no-console */
export const state = {
    messagesData: [],
    unReadMessagesCount: null
}

export const getters = {}

export const mutations = {
    SET_MESSAGES(state, data) {
        state.messagesData = data.items
        state.unReadMessagesCount = data.unread
    }
}

export const actions = {
    stateSetMessages({ commit, state, rootState }, data) {
        commit('SET_MESSAGES', data)
    }
}
